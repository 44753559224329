.footer {
  left: 0;
  bottom: 0;
  width: 100%;
  position: fixed;
  height: 3rem;
  border-top: 1px solid rgb(81, 80, 80);
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: rgb(0, 0, 0);
}

.footer a {
  width: 100px;
}

.footer a img {
  width: 100px;
  margin-left: 2rem;
}

.social_container {
  width: max-content;
  height: 100%;
  display: flex;
  flex-direction: row;
  gap: 15px;
  margin-right: 20px;
}

.footer .social_container a {
  height: 100%;
  width: max-content;
  color: white;
  display: flex;
  align-items: center;
}

@media only screen and (max-width: 600px) {
  .footer a img {
    margin-left: 14px;
  }
  .footer .social_container {
    gap: 8px;
  }
}
