@import url("https://cdnjs.cloudflare.com/ajax/libs/normalize/8.0.1/normalize.min.css");

* {
  box-sizing: border-box;
  transition: all 0.3s ease;
}

body,
.App {
  width: 100%;
  height: 100vh;
  overflow: hidden;
  /* background: url(https://www.toptal.com/designers/subtlepatterns/patterns/lightpaperfibers.png); */
  background-color: #000000f5;
  font-family: "Rethink Sans", sans-serif;
  font-feature-settings: "kern";
}

.content {
  width: 100%;
  height: 90%;
}

textarea {
  resize: none;
}

button {
  background: none;
  border: 0;
  margin: 0;
  cursor: pointer;
}

button:hover {
  color: #928405;
}

.App {
  display: flex;
}

.app-logo {
  color: #f3cc41;
  font-size: 1.55rem;
  font-weight: 600;
  margin: 0;
  padding: 0;
}

::-webkit-scrollbar {
  width: 2px;
}

/* Track */
::-webkit-scrollbar-track {
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #eed67f;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #ebe0b9;
}

@media only screen and (max-width: 400px) {
  .navbar-open {
    filter: blur(4px);
    /* Apply blur effect */
    opacity: 0.5;
    /* Make it transparent */
  }
}
