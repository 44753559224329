.app-sidebar {
  width: 30%;
  height: 100vh;
  border-right: 0.5px solid #e0d7d727;
}

.app-sidebar-header {
  display: flex;
  justify-content: space-between;
  background-color: #000;
  border-bottom: 0.5px solid #dddddd4d;
  padding: 2rem;
}

.app-sidebar-notes {
  height: calc(100vh - 78px);
  overflow-y: scroll;
  color: #fefcff;
}

.app-sidebar-note {
  cursor: pointer;
  background-color: #000;
  border-bottom: 0.5px solid #ffffff2d;
  padding: 0.75rem 2rem;
}

.fa-plus {
  color: #f3cc41;
}

.fa-trash {
  display: none;
}

.app-sidebar-note:hover .fa-trash {
  display: block;
  color: rgb(129, 7, 7);
}

.sidebar-note-title {
  display: flex;
  justify-content: space-between;
}

.app-sidebar-note button {
  color: white;
}

.app-sidebar-note .fa-trash {
  color: rgb(129, 7, 7);
}

.app-sidebar-note:hover {
  background: #ffffff0d;
  color: #ecdf95;
}

.app-sidebar-note.active {
  background: rgba(15, 14, 15, 0);
  color: #e7d882;
}

.note-meta {
  color: #808080;
  font-size: 75%;
}

@media only screen and (max-width: 600px) {
  .app-sidebar {
    width: 40%;
  }

  .app-sidebar-header h2 {
    font-size: medium;
  }

  .app-sidebar-header button {
    gap: 3px;
  }

  .app-sidebar-note:hover {
    background: #ffffff0d;
    color: #ecdf95;
  }
}

@media only screen and (max-width: 900px) {
  .app-sidebar {
    width: 40%;
  }

  .app-sidebar-header button {
    gap: 3px;
  }

  .fa-trash {
    display: block;
    color: rgb(129, 7, 7);
  }
}
