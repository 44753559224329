.app-main {
  width: 100%;
  height: 94%;
  position: relative;
}

.fa {
  justify-content: right;
}

.toggle-btn {
  background-color: #000000f6;
  height: 45px;
  font-style: normal;
  font-size: 15px;

  display: flex;
  justify-content: space-between;
  font-weight: lighter;
  background-color: #000;
}

.app-main-header {
  display: flex;
  padding: 0.5rem 1rem;
  justify-content: right;
  color: #ddd;
}

.no-active-note {
  width: 100%;
  height: 100vh;
  line-height: 100vh;
  text-align: center;
  color: #fcf1f19a;
  background-color: #000000fd;
}

/* Editing */
.app-main-note-edit {
  background-color: #25232310;
  height: 100vh;
  justify-content: center;
  font-family: "Rethink Sans", sans-serif;
}

.fontsize {
  background-color: #7171710d;
  color: #ddd;
  border: none;
  border-radius: 8px 0px 0px 8px;
  padding: 0.5rem 1rem;
}

.btn-copy {
  color: black;
  border-radius: 0px 8px 8px 0px;
  padding-right: 0.75rem;
  font-weight: bolder;
  border: none;
  background-color: #f3cc41;
}

#btn-drop {
  color: black;
  border: none;
  border-radius: 0px;
  font-weight: bolder;
  background-color: #f3cc41;
}

#btn-drop:hover {
  color: #928405;
}

.menu {
  background-color: #1a191900;
}

.main-edit-input {
  border: none;
  border-bottom: 0.5px solid #ffffff2d;
  background-color: #000;
  /* border-radius: 8px; */
  position: relative;
  width: 100%;
  will-change: transform;
  overflow: auto;
  outline: none;
  resize: none;
  font-weight: lighter;
  font-size: 16;
  font-family: "Rethink Sans", sans-serif;
  letter-spacing: 0rem;
  line-height: 1.6em;
  text-align: start;
  color: #ffffff70;
  text-decoration: none;
  text-transform: none;

  padding: 1rem 2rem;
}

#title {
  color: rgb(194, 201, 194);
}

textarea {
  display: block;
  border: none;
  border-top: 0.5px solid #ffffff2d;
  width: 100%;
  height: 80%;
  resize: none;
  font-size: 16;
  background-color: #000;
  height: 80%;
  position: relative;
  will-change: transform;
  overflow: auto;
  outline: none;
  resize: none !important;
  letter-spacing: 0;
  line-height: 1.6em;
  text-align: start;
  color: #ffffff;
  text-decoration: none;
  text-transform: none;
}

.textarea-footer {
  /* border-top: 0.5px solid #ffffff2d; */
  color: #ffffff70;
  font-style: normal;
  font-size: 15px;

  display: flex;
  justify-content: space-between;
  font-weight: lighter;
  background-color: #000;
}

.words {
  font-style: normal;
  margin: 2rem;
}

/* screen size adaption need to be done */
.chars {
  font-style: normal;
}

#menu-drop {
  border-radius: 0%;
  background-color: rgb(18 17 17 / 98%);
  color: white;
}

#menu-item {
  color: #ddd;
  font-size: 13px;
}

#menu-item:hover {
  background-color: #000000;
}

.dropdown-item {
  background-color: #000000db;
}

@media only screen and (max-width: 900px) {
  .navbar-open .app-main {
    background-color: #a83838;
  }

  .app-main-header {
    font-size: smaller;
  }
  .no-active-note {
    width: 100%;
    height: 95%;
    position: relative;
    background-color: #000;
  }
  .cnn {
    font-size: 20px;
    color: #f3cc41;
  }
  .add-btn {
    font-style: normal;
    height: 25%;
    font-size: 15px;
    font-size: 15px;

    justify-content: space-between;
    font-weight: lighter;
    background-color: #000;
    color: #41f3db;
  }
  .fa {
    color: #f3cc41;
  }

  .toggle-btn {
    display: block;
    color: #41f3db;
  }
  textarea {
    height: 70%;
  }

  .fontsize {
    width: 43px;
  }
}

@media only screen and (max-width: 400px) {
  textarea {
    height: 65%;
  }
  .no-active-note {
    width: 600px;
  }
}
